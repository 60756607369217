window.app.controller('SignInCtrl', ['$scope', '$location', '$routeParams', '$rootScope', '$translate', 'AppFactory', 'AuthService', 'LoaderFactory', 'ReducedService', 'INDEX_URL', 'Notification', 'UnsupportedBrowsersDialogFactory', 'SyncService', 'LocalizationService', 'appId', 
                             function($scope,   $location,   $routeParams,   $rootScope,   $translate,   AppFactory,   AuthService,   LoaderFactory,   ReducedService,   INDEX_URL,   Notification,   UnsupportedBrowsersDialogFactory,   SyncService,   LocalizationService,   appId) {

  $scope.form = {
    email: $routeParams.email || '',
    password: '',
    message: $routeParams.message || null,
    uuid: ''
  };

  $scope.userForm = null;

  $scope.INDEX_URL = INDEX_URL;

  $scope.offRegister = ReducedService.registration;
  $scope.offActivationExistingAccount = ReducedService.activation_existing_account;
  $scope.showUnsupportedBrowsersDialog = UnsupportedBrowsersDialogFactory.isShowUnsupportedBrowsersDialog;
  $scope.langList = LocalizationService.supportLangs;
  $scope.firstLogin = !+localStorage.getItem('firstLoginActivatePopup');
  $rootScope.currentLang = localStorage.getItem('lang');

  let justActivated = JSON.parse(localStorage.getItem('activation_creds') || null);
  if (justActivated) {
    localStorage.removeItem('activation_creds');
    if (justActivated && justActivated.email && justActivated.pass) {
      $scope.form.email = justActivated.email;
      $scope.form.password = justActivated.pass;
    }
  }

  let latestPersonalNumber = localStorage.getItem('latest_personal_number');
  if (!justActivated && latestPersonalNumber && !isNaN(parseInt(latestPersonalNumber))) $scope.form.email = latestPersonalNumber;

  $scope.isActive = (key) => !ReducedService[key];

  $scope.getFlag = (code) => {
    switch(code) {
      case 'de':
        return 'images/flags/germany.svg';
      case 'en':
        return 'images/flags/united-kingdom.svg';
      case 'ro':
        return 'images/flags/romania.svg';
      case 'pl':
        return 'images/flags/poland.svg';
      case 'tr':
        return 'images/flags/turkey.svg';
      case 'hu':
        return 'images/flags/hungary.svg';
      case 'ru':
        return 'images/flags/russia.svg';
      case 'cs':
        return 'images/flags/czech-republic.svg';
      case 'hr':
        return 'images/flags/croatia.svg';
      case 'uk':
        return 'images/flags/ukraine.svg';
      case 'es':
        return 'images/flags/spain.svg';
      case 'it':
        return 'images/flags/italy.svg';
      case 'fr':
        return 'images/flags/france.svg';
    };
  }

  $scope.getLangName = (code) => {
    switch(code) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
      case 'ro':
        return 'Română';
      case 'pl':
        return 'Polski';
      case 'tr':
        return 'Türk';
      case 'hu':
        return 'Magyar';
      case 'ru':
        return 'Русский';
      case 'cs':
        return 'Čeština';
      case 'hr':
        return 'Hrvatski';
      case 'uk':
        return 'Українська';
      case 'es':
        return 'Español';
      case 'it':
        return 'Italiano';
      case 'fr':
        return 'Français';
    };
  }

  $scope.toggleLangPicker = () => {
    let langList = document.getElementsByClassName('lang-list')[0];
    if (langList.classList.contains('active')) langList.style.height = 0;
    else langList.style.height = 40 * $scope.langList.length - 19 + 'px';
    langList.classList.toggle('active');
  }

  $scope.hideLangPicker = () => {
    if (window.cordova) {
      document.getElementById('login-lang-picker').classList.add('hidden');
    }
  }

  $scope.showLangPicker = () => {
    if (window.cordova) {
      document.getElementById('login-lang-picker').classList.remove('hidden');
    }
  }

  $scope.onSwitchLang = function (langCode) {
    $rootScope.currentLang = langCode;
    localStorage.setItem('selectedLang', langCode);
    LocalizationService.localization(langCode);
    $scope.toggleLangPicker();
    return Notification.alert_p({
      title: 'note', 
      desc: 'languageChanged', 
      button: 'ok'
    });
  }

  $scope.submitForm = function() {
    if(!$scope.form.email || $scope.form.email.trim() == 0) {
      return $translate(['error', 'enterEmail']).then(function(result) {
        return Notification.alert({
          title: result['error'],
          desc: result['enterEmail']
        });
      });
    } else if ($scope.form.password.trim() == 0) {
      return $translate(['error', 'enterPassword']).then(function(result) {
        return Notification.alert({
          title: result['error'],
          desc: result['enterPassword']
        });
      });
    }


    if (!AppFactory.isNetwork()) AppFactory.networkProblem();
    else {
      LoaderFactory.show();

      if (window.cordova) $scope.form.uuid = device.uuid;

      AuthService.signIn({
        email:        $scope.form.email.trim(),
        password:     $scope.form.password.trim(),
        uuid:         $scope.form.uuid,
        manufacturer: window?.device?.manufacturer ? window.device.manufacturer : '',
        model:        window?.device?.model        ? window.device.model        : '',
        app_name:     window.cordova ? deviceIsAndroid ? appId.android : appId.ios : null
      })
      .then(() => {
        if (!isNaN(parseInt($scope.form.email))) localStorage.setItem('latest_personal_number', $scope.form.email);
        else localStorage.setItem('latest_personal_number', '');
      })
      .then(() => {
        if (!ReducedService.login) return AuthService.getAuthorizedUser().update()
      })
      .then(() => AppFactory.checkNewVersion())
      .then(() => {
        $location.path(INDEX_URL);
        $scope.$apply();
      })
      .then(() => {
        if ( !(/.*?preview=true$/.test(location.hash)) ) {
          return SyncService.syncAfterAppLoad()
        }
      })
      .catch(function(err) {
        if (err && err.store_url) {
          $location.path('/blocker');
          $scope.$apply();
        }
        if (err.notify) err.notify();
        else console.error(err);
      })
      .then(function() {
        LoaderFactory.hide();
      });
    }
  }

  if (window.cordova && window.navigator.splashscreen) window.navigator.splashscreen.hide();

}]);
